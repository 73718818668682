<template>
  <div>
    <p>Total Number of Providers: {{ providers.length }}</p>

    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Linking Code</th>
          <th>Invite Date</th>
          <th>Last Modified</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="provider in providers" :key="provider.id">
          <td>{{ provider.firstName }} {{ provider.lastName }}</td>
          <td>{{ provider.email }}</td>
          <td>
            {{ provider.linkingCode }}
          </td>
          <td>
            {{ provider.createdDateFormat }}
          </td>
          <td>{{ provider.lastModifiedDateFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import "../extensions/DateExtensions";

export default defineComponent({
  setup() {
    const store = useStore();
    const providers = computed(() => store.state.providers);

    return {
      providers,
    };
  },
});
</script>

<style></style>
