
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import "../extensions/DateExtensions";

export default defineComponent({
  setup() {
    const store = useStore();
    const providers = computed(() => store.state.providers);

    return {
      providers,
    };
  },
});
